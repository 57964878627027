<template>
  <div>
    <!-- <v-container fluid class="down-top-padding"> -->
    <vue-element-loading
      :active="appLoading"
      spinner="bar-fade-scale"
      color="#1976d2"
      size="128"
      is-full-screen
    />
    <ServerError v-if="ServerError" />
    <v-snackbar v-model="showsnackbar" color="black" right>
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">{{ msg }}</v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showsnackbar = false">
            <v-icon style="color: white">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout wrap justify-center>
      <v-flex px-5 pt-5 xs12 sm12 md12 lg12 xl12 text-center>
        <v-form ref="form" lazy-validation>
          <v-layout wrap>
            <v-flex xs12 pt-2>
              <v-card>
                <v-card-title class="elevation-1">
                  Styl Categories
                  <v-spacer></v-spacer>
                  <v-col cols="12" sm="3">
                    <v-text-field
                      v-model="search"
                      append-icon="mdi-magnify"
                      solo
                      label="Search"
                      single-line
                      hide-details
                    ></v-text-field>
                  </v-col>
                  <v-row justify="end" class="px-5">
                    <v-dialog
                      :retain-focus="false"
                      v-model="dialog"
                      persistent
                      max-width="800px"
                      :key="dialog"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn outlined color="green" v-bind="attrs" v-on="on">
                          Add
                        </v-btn>
                      </template>
                      <v-card>
                        <v-form v-model="addcat" ref="addcat">
                          <v-card-title>
                            <span class="headline">Add Styl-Category</span>
                          </v-card-title>
                          <v-card-text>
                            <v-container>
                              <v-row>
                                <v-col cols="12" sm="12" md="8">
                                  <v-text-field
                                    v-model="name"
                                    label="Styl-Category Name"
                                    :rules="[rules.required]"
                                    required
                                  ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="12" md="4">
                                  <v-text-field
                                    v-model="order"
                                    type="number"
                                    label="Order of Style Category"
                                    :rules="[rules.required]"
                                    required
                                  ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="12" md="12">
                                  <ImageComp
                                    :singleImage="editingitem.image"
                                    :pageId="editingitem._id"
                                    @stepper="winStepper"
                                    :height="330"
                                    :width="210"
                                    :heading="'Upload Styl Image (210 X 330)'"
                                    :componentType="'stylImage'"
                                  />
                                </v-col>
                              </v-row>
                            </v-container>
                          </v-card-text>
                          <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                              color="blue darken-1"
                              text
                              @click="dialogclose()"
                            >
                              Close
                            </v-btn>
                            <v-btn
                              color="blue darken-1"
                              text
                              :disabled="!addcat"
                              @click="categoryadd()"
                            >
                              Save
                            </v-btn>
                          </v-card-actions>
                        </v-form>
                      </v-card>
                    </v-dialog>
                  </v-row>
                </v-card-title>
                <div v-if="category.length > 0">
                  <v-layout wrap pt-10 pb-10>
                    <v-flex v-for="(item, i) in category" :key="i" md3 pa-4>
                      <v-card style="line-height: 16px" tile flat>
                        <div v-viewer style="display: flex">
                          <img
                            style="
                              width: 100%;
                              object-fit: contain;
                              cursor: pointer;
                            "
                            :src="baseURL + item.image"
                            height="300px"
                          />
                        </div>
                        <template v-slot:placeholder>
                          <v-row
                            class="fill-height ma-0"
                            align="center"
                            justify="center"
                          >
                            <v-progress-circular
                              indeterminate
                              color="#FF2323"
                            ></v-progress-circular>
                          </v-row>
                        </template>
                        <v-layout wrap justify-center px-2>
                          <v-flex pt-2 xl12 md12 lg12 xs12 sm12>
                            <span style="font-size: 13px; color: #000000">
                              {{ item.name }} </span
                            ><br /><br />
                          </v-flex>
                          <v-flex
                            pt-5
                            xl6
                            lg6
                            md6
                            pb-2
                            sm6
                            xs6
                            px-5
                            justify-center
                          >
                            <v-dialog v-model="item.delete" max-width="600px">
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                  small
                                  class="mr-2"
                                  outlined
                                  block
                                  v-on="on"
                                  v-bind="attrs"
                                  color="red"
                                >
                                  Delete
                                </v-btn>
                              </template>
                              <v-card>
                                <v-card-title
                                  >Are you sure you want to delete this
                                  Styl-Category?</v-card-title
                                >
                                <v-card-actions>
                                  <v-spacer></v-spacer>
                                  <v-btn
                                    color="blue darken-1"
                                    text
                                    @click="item.delete = false"
                                    >Cancel</v-btn
                                  >
                                  <v-btn
                                    color="blue darken-1"
                                    text
                                    @click="deleteItem(item)"
                                    >OK</v-btn
                                  >
                                  <v-spacer></v-spacer>
                                </v-card-actions>
                              </v-card>
                            </v-dialog>
                          </v-flex>
                          <v-flex
                            pt-5
                            xl6
                            lg6
                            md6
                            pb-2
                            sm6
                            xs6
                            px-5
                            justify-center
                          >
                            <v-btn
                              small
                              class="mr-2"
                              outlined
                              block
                              color="green"
                              @click="editcat(item)"
                            >
                              Edit
                            </v-btn>
                          </v-flex>
                          <v-flex
                            pt-5
                            xl6
                            lg6
                            md6
                            pb-2
                            sm6
                            xs6
                            px-5
                            justify-center
                          >
                            <v-btn
                              v-if="item.status == 'active'"
                              small
                              class="mr-2"
                              outlined
                              block
                              color="red"
                              @click="
                                (editingitem = item),
                                  (changeStatusDialog = true)
                              "
                            >
                              <span>Disable</span>
                            </v-btn>
                            <v-btn
                              v-if="item.status == 'inactive'"
                              small
                              class="mr-2"
                              outlined
                              block
                              @click="
                                (editingitem = item),
                                  (changeStatusDialog = true)
                              "
                              color="blue"
                            >
                              <span>Enable </span>
                            </v-btn>
                          </v-flex>
                          <br />
                        </v-layout>
                      </v-card>
                    </v-flex>
                    <v-dialog v-model="changeStatusDialog" max-width="600px">
                      <v-card>
                        <v-card-title
                          >Are you sure you want to&nbsp;
                          <span
                            class="px-2"
                            v-if="editingitem.status == 'active'"
                          >
                            disable
                          </span>
                          <span
                            class="px-2"
                            v-if="editingitem.status == 'inactive'"
                          >
                            enable
                          </span>
                          &nbsp;this Styl-Category?</v-card-title
                        >
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn
                            color="blue darken-1"
                            text
                            @click="changeStatusDialog = false"
                            >Cancel</v-btn
                          >
                          <v-btn
                            color="blue darken-1"
                            text
                            @click="changeStatusItem(item)"
                            >OK</v-btn
                          >
                          <v-spacer></v-spacer>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                    <v-dialog
                      v-model="editdialog"
                      :retain-focus="false"
                      max-width="800px"
                    >
                      <v-card>
                        <v-card-title>
                          <span class="headline">Edit Styl-Category </span>
                        </v-card-title>
                        <v-card-text>
                          <v-container>
                            <v-row>
                              <v-col cols="12" sm="6" md="8">
                                <v-text-field
                                  v-model="editingitem.name"
                                  label="Styl-Category Name"
                                ></v-text-field>
                              </v-col>
                              <v-col cols="12" sm="6" md="4">
                                <v-text-field
                                  v-model="editingitem.order"
                                  label="Order of Style Category"
                                  :rules="[rules.required]"
                                  type="number"
                                  required
                                ></v-text-field>
                              </v-col>
                              <v-col cols="12" sm="12" md="12">
                                <ImageComp
                                  :singleImage="editingitem.image"
                                  :pageId="editingitem._id"
                                  @stepper="winStepper"
                                  :height="330"
                                  :width="210"
                                  :heading="'Upload Styl Image (210 X 330)'"
                                  :componentType="'stylImage'"
                                />
                              </v-col>
                            </v-row>
                          </v-container>
                        </v-card-text>

                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn
                            color="blue darken-1"
                            text
                            @click="editdialog = false"
                          >
                            Cancel
                          </v-btn>
                          <v-btn color="blue darken-1" text @click="edit()">
                            Save
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                  </v-layout>
                </div>

                <!-- <div class="pt-2 blue-grey lighten-5">
                  <v-pagination
                    :length="pages"
                    :total-rows="totalData"
                    v-model="currentPage"
                  ></v-pagination> -->
                <!-- </div> -->
              </v-card>
            </v-flex>
          </v-layout>
        </v-form>
      </v-flex>
    </v-layout>
    <!-- </v-container> -->
  </div>
</template>
<script>
import axios from "axios";
import ImageComp from "@/components/commonComponents/singleImage";
export default {
  components: {
    ImageComp,
  },
  data() {
    return {
      ServerError: false,
      showsnackbar: false,
      stylImage: null,
      msg: null,
      page: 5,
      count: 20,
      dialog: false,
      editdialog: false,
      editingitem: {},
      name: "",
      order: null,
      pageCount: 1,
      appLoading: false,
      editedIndex: -1,
      currentPage: 1,
      perPage: 4,
      totalData: 0,
      totalRows: 0,
      dialogDelete: false,
      changeStatusDialog: false,
      search: "",
      itemname: "",
      imgId: "",
      addcat: false,
      headers: [
        { text: "Styl-Category Name", value: "name" },
        // { text: "Username", value: "username" },
        // { text: "Phone", value: "phone" },
        // { text: "Email", value: "email" },
        { text: "Actions", value: "_id", sortable: false },
      ],
      rules: {
        required: (value) => !!value || "Required.",
        min: (v) => v.length >= 8 || "Min 8 characters",
      },
      category: [],
      data: [],
    };
  },
  mounted() {
    this.getData();
  },
  watch: {},
  created() {
    this.initialize();
  },
  methods: {
    initialize() {
      this.admin = [];
    },
    winStepper(window_data) {
      if (window_data.type == "stylImage") {
        this.stylImage = window_data.selectedFiles;
      }
    },

    deleteItem(cat) {
      var data = {};
      data["id"] = cat._id;
      axios({
        url: "/styl/remove",
        method: "POST",
        data: data,
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.delete = false;
          this.appLoading = false;
          if (response.data.status) {
            this.msg = "Delete Sucessfully";
            this.showsnackbar = true;

            this.getData();
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    changeStatusItem() {
      var data = {};
      data["id"] = this.editingitem._id;
      if (this.editingitem.status == "active") data["status"] = "inactive";
      if (this.editingitem.status == "inactive") data["status"] = "active";
      axios({
        url: "/styl/status",
        method: "POST",
        data: data,
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.delete = false;
          this.appLoading = false;
          if (response.data.status) {
            this.msg = response.data.msg;
            this.showsnackbar = true;
            this.changeStatusDialog = false;
            this.getData();
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    getData() {
      this.appLoading = true;
      axios({
        url: "/styl/getlistAll",
        method: "GET",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.category = response.data.data;
          this.totalData = response.data.count;
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    dialogclose() {
      this.name = null;
      this.dialog = false;
    },
    categoryadd() {
      var data = {};
      data["name"] = this.name;
      data["order"] = Number(this.order);

      axios({
        url: "/styl/add",
        method: "POST",
        data: data,
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.imgId = response.data.id;
            this.uploadImage(response.data.id);
            this.getData();
            this.msg = "Added Sucessfully";
            this.showsnackbar = true;
            this.name = null;
            this.dialog = false;
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    uploadImage(id) {
      let formData = new FormData();
      formData.append("id", id);
      formData.append("image", this.stylImage);
      axios({
        method: "POST",
        url: "/styl/uploadimage",
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data",
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.getData();
            this.showsnackbar = true;
            this.msg = "Uploaded Successfully";
            this.dialog = false;
            this.dialogmain = false;
            this.exclusiveMainImage = null;
            this.exclusiveImage = null;
          } else {
            this.msg = "Can't Update";
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    editcat(item) {
      this.editingitem = item;
      this.editdialog = true;
    },
    edit() {
      var data = {};
      data["id"] = this.editingitem._id;
      data["name"] = this.editingitem.name;
      data["order"] = Number(this.editingitem.order);
      axios({
        url: "/styl/edit",
        method: "POST",
        data: data,
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.editdialog = false;
          this.appLoading = false;
          if (response.data.status) {
            if (this.stylImage) {
              this.uploadImage(this.editingitem._id);
            }
            this.msg = "Edited Sucessfully";
            this.showsnackbar = true;
            this.getData();
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
  },
};
</script>

<style scoped>
.table-striped:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}
</style>
